import SafetyCulture from '@/icons/custom/SafetyCulture';
import styles from './Badge.module.scss';
import Typography from '@/components/atoms/Typography';
import cn from 'classnames';
import background from '@/styles/modules/Backgrounds.module.scss';
import typeConfig from '@/utilities/types/Config';

interface BadgeProps {
  label?: string;
  company?: 'safetycultre' | 'none';
  backgroundColor: typeConfig['color'] | string;
  style?: 'classic' | 'company';
  className?: string;
}

const Badge = (props: BadgeProps) => {
  const { label, company, backgroundColor, style, className } = props;

  return (
    <div
      className={cn(
        className,
        style ? styles[style] : styles.company,
        backgroundColor && background[backgroundColor],
        styles.root,
      )}
    >
      {label && (
        <Typography xs={2} tag="span" family="noto-sans" weight="regular">
          {label}
        </Typography>
      )}
      {company === 'safetycultre' && (
        <i>
          <SafetyCulture width={24} height={24} />
        </i>
      )}
    </div>
  );
};

export default Badge;
