import { IconProps } from '@/utilities/types/Icon';

const Calendar = (props: IconProps) => {
  const { height = 20, width = 22 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 20 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M6.008 16a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM5.008 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM13.992 12a1 1 0 1 0 0 2 1 1 0 0 0 0-2ZM9 17a1 1 0 1 1 2 0 1 1 0 0 1-2 0ZM9.992 12a1 1 0 1 0 0 2 1 1 0 0 0 0-2Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M15 1a1 1 0 1 0-2 0v1H7V1a1 1 0 1 0-2 0v1h-.839c-.527 0-.981 0-1.356.03-.395.033-.789.104-1.167.297A3 3 0 0 0 .327 3.638c-.193.378-.264.772-.296 1.167C0 5.18 0 5.635 0 6.161v11.677c0 .528 0 .982.03 1.357.033.395.104.789.297 1.167a3 3 0 0 0 1.311 1.311c.378.193.772.264 1.167.296.375.031.83.031 1.356.031H15.84c.527 0 .981 0 1.356-.03.395-.033.789-.104 1.167-.297a3 3 0 0 0 1.311-1.311c.193-.378.264-.772.296-1.167.031-.375.031-.83.031-1.356V6.16c0-.527 0-.981-.03-1.356-.033-.395-.104-.789-.297-1.167a3 3 0 0 0-1.311-1.311c-.378-.193-.772-.264-1.167-.296A17.9 17.9 0 0 0 15.838 2H15V1Zm3 5.2V8H2V6.2c0-.577 0-.949.024-1.232.022-.272.06-.373.085-.422a1 1 0 0 1 .437-.437c.05-.025.15-.063.422-.085C3.25 4 3.623 4 4.2 4h11.6c.577 0 .949 0 1.232.024.272.022.372.06.422.085a1 1 0 0 1 .437.437c.025.05.063.15.085.422C18 5.25 18 5.623 18 6.2Zm0 3.8H2v7.8c0 .577 0 .949.024 1.232.022.272.06.372.085.422a1 1 0 0 0 .437.437c.05.025.15.063.422.085C3.25 20 3.623 20 4.2 20h11.6c.577 0 .949 0 1.232-.024.272-.022.372-.06.422-.085a1 1 0 0 0 .437-.437c.025-.05.063-.15.085-.422C18 18.75 18 18.377 18 17.8V10Z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Calendar;
