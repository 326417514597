import { IconProps } from '@/utilities/types/Icon';

const SafetyCulture = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 24 18"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="gold"
        d="M21.903 14.662h-6.575a2.484 2.484 0 0 1-1.56 1.789l6.884-.448a1.45 1.45 0 0 0 1.393-1.167.144.144 0 0 0-.142-.174Z"
      />
      <path
        fill="#00D1FF"
        d="M15.328 14.662H8.334c0 .004 0 .009-.002.012a3.02 3.02 0 0 1-1.978 2.26l7.414-.483a2.484 2.484 0 0 0 1.56-1.789Z"
      />
      <path
        fill="#6559FF"
        d="M.696 17.117a.146.146 0 0 0 .147.176l5.511-.359a3.018 3.018 0 0 0 1.978-2.26c.002-.005 0-.009.002-.012h-4.64a3.056 3.056 0 0 0-2.998 2.455Z"
      />
      <path
        fill="#293745"
        d="M22.642 7.838c-1.492 2.536-5.588 4.424-7.816 2.217-1.502-1.49-.96-3.502.037-4.773.99-1.263 3.222-2.127 4.577-.941.36.315.607.725.743 1.228.04.15.186.245.338.215l2.428-.462a.438.438 0 0 0 .355-.482c-.082-.729-.479-2.28-2.315-3.444-.798-.505-1.745-.687-2.758-.687a7.795 7.795 0 0 0-2.985.58 7.581 7.581 0 0 0-2.333 1.475c.132.328.22.683.257 1.062a1.162 1.162 0 0 1-.947 1.263l-1.052.2c-.109.257-.2.517-.278.78a3.363 3.363 0 0 1 1.168 1.139c.443.712.634 1.595.536 2.487a4.986 4.986 0 0 1-.735 2.099c1.468 1.672 3.99 2.23 6.117 1.724 2.097-.499 4.883-2.21 5.256-5.52.045-.414-.39-.504-.593-.16Z"
      />
      <path
        fill="#293745"
        d="M11.439 7.592a2.64 2.64 0 0 0-.726-.768l-.043-.032c-.969-.654-2.193-.735-3.269-1.142-.328-.123-.736-.29-.862-.642-.16-.454.145-.925.555-1.13a1.93 1.93 0 0 1 .84-.179c.145 0 .286.015.415.042a.982.982 0 0 1 .79.88.287.287 0 0 0 .347.24l2.09-.398.511-.096a.437.437 0 0 0 .356-.47 3.267 3.267 0 0 0-.1-.538C11.806 1.385 9.569.986 8.473.986c-1.063 0-1.99.161-2.779.482-.79.32-1.414.815-1.874 1.486-.46.669-.69 1.477-.69 2.421 0 1.17.737 2.016 1.806 2.464.803.337 1.683.463 2.509.744.685.234 1.249.717.886 1.468-.273.563-.958.823-1.563.823-.89 0-1.28-.745-1.424-1.136a.291.291 0 0 0-.332-.183l-2.67.508a.435.435 0 0 0-.355.475c.094.816.466 1.716 1.122 2.233.767.604 1.816.907 3.149.907 1.096 0 2.065-.174 2.907-.522a4.462 4.462 0 0 0 2.224-2.004c.253-.473.421-.993.481-1.54.076-.707-.063-1.429-.43-2.02Z"
      />
    </svg>
  );
};

export default SafetyCulture;
