import { IconProps } from '@/utilities/types/Icon';

const Clock = (props: IconProps) => {
  const { height = 22, width = 22 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 22 22"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 5v6l4 2m6-2c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10Z"
      />
    </svg>
  );
};

export default Clock;
