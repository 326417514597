export const LEVER = {
  learnMore: {
    au: 'Learn more',
    de: 'Mehr erfahren',
    en: 'Learn more',
    es: 'Saber más',
    fr: 'En savoir plus',
    nl: 'Meer informatie',
    'pt-br': 'Saiba mais',
    us: 'Learn more',
  },
  jobSearch: {
    au: 'Search by job type',
    de: 'Nach Stellentyp suchen',
    en: 'Search by job type',
    es: 'Buscar por tipo de trabajo',
    fr: 'Rechercher par type de poste',
    nl: 'Zoeken op functietype',
    'pt-br': 'Pesquisar por tipo de trabalho',
    us: 'Search by job type',
  },
  jobLocation: {
    au: 'Search by location',
    de: 'Nach Standort suchen',
    en: 'Search by location',
    es: 'Buscar por ubicación',
    fr: 'Rechercher par lieu',
    nl: 'Zoeken op locatie',
    'pt-br': 'Pesquisar por localização',
    us: 'Search by location',
  },
  all: {
    au: 'All',
    de: 'Alle',
    en: 'All',
    es: 'Todo',
    fr: 'Tout',
    nl: 'Alle',
    'pt-br': 'Tudo',
    us: 'All',
  },
  notFound: {
    au: 'No results found, please try again',
    de: 'Keine Ergebnisse gefunden, bitte versuchen Sie es erneut',
    en: 'No results found, please try again',
    es: 'No se encontraron resultados, por favor inténtelo de nuevo',
    fr: 'Aucun résultat trouvé, veuillez réessayer',
    nl: 'Geen resultaten gevonden, probeer het opnieuw',
    'pt-br': 'Nenhum resultado encontrado, por favor tente novamente',
    us: 'No results found, please try again',
  },
  reset: {
    au: 'Reset',
    de: 'Zurücksetzen',
    en: 'Reset',
    es: 'Restablecer',
    fr: 'Réinitialiser',
    nl: 'Herstellen',
    'pt-br': 'Redefinir',
    us: 'Reset',
  },
} as const;
